import { DateTime } from "luxon";
import React, { SyntheticEvent, useEffect, useRef } from "react";
import { ignoreAlerts, markAlertAsSeen, submitTicket } from "apps-middleware/api/alert";
import { updatePlantThunk } from "apps-middleware/redux/asyncThunks";
import { useAppDispatch } from "apps-middleware/redux/store/hooks";
import { IPlantAlert } from "apps-middleware/types/plant";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { alertPriorityToColour } from "apps-middleware/constants/plant";
import { LocalDateTimeString } from "apps-middleware/util/time";
import { SplitButton } from "components/CustomButtons/SplitButton";
import { Create } from "@mui/icons-material";
import SimpleConfirm from "components/Modals/SimpleConfirm";

const UNSEEN_BACKGROUND = "rgba(255,0,0,0.1)";

export interface IAlertEntryProps {
  plantId: string,
  alert: IPlantAlert,
}

function AlertEntry({ plantId, alert }: IAlertEntryProps): JSX.Element {
  const dispatch = useAppDispatch();
  const alertRendered = useRef(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [forceUpdate, setForceUpdate] = React.useState<number>(1);
  const [confirmMessage, setConfirmMessage] = React.useState<string | null>(null);
  const forceUpdatePlant = () => {
    if (alertRendered && alertRendered.current) setForceUpdate(forceUpdate + 1);
  };

  const circleSize = 20;

  const ignoreOptions: string[] = [
    "For 24 Hours",
    "Forever"
  ];

  useEffect(function onLoad() {
    alertRendered.current = true;

    return function onUnload() {
      alertRendered.current = false;
    };
  }, []);

  useEffect(function listenForRequestsToUpdatePlant() {
    if (forceUpdate === 1) return; //dont do it on load

    async function updatePlant() {
      if (alertRendered && alertRendered.current) setIsLoading(true);
      await dispatch(updatePlantThunk(plantId));
      if (alertRendered && alertRendered.current) setIsLoading(false);
    }

    updatePlant();

  }, [forceUpdate]);

  function handleIgnore(optionIndex: number) {
    const hasExpiry = optionIndex === 0;
    const expiryUnixTimestamp = DateTime.now().plus({ hours: 24 })
      .toMillis();

    ignoreAlerts({
      plantDataSourceIds: [alert.plantDataSourceId],
      alertTypes: [alert.type],
      ...(hasExpiry && { expiryUnixTimestamp: expiryUnixTimestamp }),
      ignore: true
    })
      .then(forceUpdatePlant);
  }

  function markAsSeen(e: SyntheticEvent<HTMLButtonElement>) {
    e.preventDefault();
    markAlertAsSeen({
      alerts: [{
        alertId: alert.alertId ?? "",
        seen: !alert.seen,
      }],
    })
      .then(forceUpdatePlant);
  }

  async function handleOpenTicket(e: SyntheticEvent<HTMLButtonElement>) {
    const { alertId } = alert;
    if (alertId) {
      const response = await submitTicket(alertId);
      if (response && response.status === 204) {
        setConfirmMessage(
          "An email has been sent to support!\n\nThis alert will now be marked as read.");
        markAlertAsSeen({
          alerts: [{ alertId, seen: true }]
        }).then(forceUpdatePlant);
      } else {
        setConfirmMessage(
          "There was an error while sending the ticket request. Please try again later.");
      }
    }
    /// ////////
    e.stopPropagation();
  }

  return (
    <React.Fragment>
      <SimpleConfirm
        open={confirmMessage !== null}
        close={() => setConfirmMessage(null)}
        title={"Support Ticket Status"}
        message={confirmMessage ?? ""}
      />
      <Grid
        item xs={12}
        container
        gap={2}
        alignItems={"center"}
        flexDirection="row"
        flexWrap={"nowrap"}
        padding={1}
        paddingY={0.5}
        style={{
          backgroundColor: !alert.seen ? UNSEEN_BACKGROUND : "",
          borderRadius: 5,
        }}
        justifyContent="space-between">
        <Grid item xs="auto">
          <div
            style={{
              width: circleSize,
              height: circleSize,
              borderRadius: circleSize / 2,
              backgroundColor: alertPriorityToColour[alert.priority],
              borderColor: "black",
              borderWidth: 3,
              alignSelf: "center",
            }}
          />
        </Grid>
        <Grid item xs="auto">
          <Typography>
            {LocalDateTimeString(DateTime.fromMillis(alert.created))}
          </Typography>
        </Grid>    <Grid item xs="auto">
          <Divider orientation="vertical" flexItem style={{ paddingLeft: 10 }} />
        </Grid>
        <Grid item xs>
          <Typography>
            {alert.type} - {alert.description}
          </Typography>
        </Grid>
        <Grid item container gap={2} xs="auto">
          <Button
            disabled={isLoading}
            variant="outlined"
            color="success"
            onClick={markAsSeen}>
        Mark As {alert.seen ? "Unread" : "Read"}
          </Button>
          <SplitButton
            disabled={isLoading}
            variant="outlined"
            color="warning"
            options={ignoreOptions}
            onButtonClick={() => handleIgnore(0)}
            onMenuItemClick={handleIgnore}>
        Ignore
          </SplitButton>

          <Button
            disabled={isLoading}
            startIcon={<Create />}
            variant="outlined"
            color="info"
            onClick={handleOpenTicket}>
        Ticket
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default React.memo(AlertEntry);
