import { request } from ".";
import {
  IFetchAlertsQueryParams, IFetchAlertsResponse,
  IIgnoreAlertQueryParams, IMarkAlertAsSeenQueryParams, ISubmitTicketParams,
  RequestResult,
} from "../types/server";

export const fetchAlerts = async (params: IFetchAlertsQueryParams):
  RequestResult<IFetchAlertsResponse> =>
  request("v2/plant/getAlertList", "POST", params);

export const ignoreAlerts = async (params: IIgnoreAlertQueryParams):
  RequestResult<null> =>
  request("v2/plant/setAlertIgnoreStatus", "POST", params);

export const markAlertAsSeen = async (
  params: IMarkAlertAsSeenQueryParams,
):
  RequestResult<null> =>
  request(
    "v1/plant/alertMarkAsSeen",
    "POST",
    params
  );

export const submitTicket = async (alertId: string):
  RequestResult<null> =>
  request(`v3/alerts/${alertId}/support`, "POST");